<template>
  <v-col>
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-form ref="form" v-model="valid">
            <v-card-title class="pa-8 pb-2">Topik</v-card-title>
            <div class="px-8 pb-4">
              <v-row>
                <v-col>
                  <div class="font-weight-medium mb-2">Judul Topik</div>
                  <v-text-field
                    outlined
                    placeholder="Masukkan judul topik"
                    v-model="title_topik"
                    required
                    :rules="[...rules('Judul Topik', 'required'), validate]"
                    :disabled="isDisabled"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-card-actions v-if="this.$route.name == 'activity-edit'" class="background">
              <v-spacer></v-spacer>
              <!-- <v-btn color="primary" :loading="submitting" @click="save()">Simpan</v-btn> -->
            </v-card-actions>
            <v-card-actions v-else class="background">
              <v-spacer></v-spacer>
              <v-btn color="primary" :loading="submitting" @click="save()">Simpan</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>

      <v-col v-if="isLoading" cols="12" class="text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
      <activity-form
        v-else
        ref="actForm"
        v-for="(activity, index) in activities"
        :seq="index + 1"
        :key="activity.id"
        :activity="activity"
        :submiting="submitting"
        @click:submit="submit"
        @update:activity="$set(activities, index, $event)"
        @click:cancel="
          $router.push({ name: 'program-detail', params: { id: $route.params.program } })
        "
      ></activity-form>

      <v-snackbar top v-model="snackbar.state" :timeout="timeout" color="primary" outlined>
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </v-col>
</template>

<script>
import ActivityForm from "./ActivityForm";
import validationMixin from "../../_mixins/validation";

export default {
  mixins: [validationMixin],
  components: { ActivityForm },
  data: () => ({
    activities: [],
    aktifitas: false,
    simpan: true,
    title_topik: "",
    submitting: false,
    valid: true,
    errors: null,
    isDisabled: false,
    snackbar: {
      state: false,
      text: "",
    },
    isLoading: null,
    timeout: 5000,
  }),
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
  },
  created() {
    if (this.$route.name == "activity-edit") {
      this.fetchACtivity();
    }
  },
  methods: {
    async submit(data) {
      if (data instanceof Error) {
        let message = data.message;
        if (data.response?.data?.message) {
          message = data.response.data.message;
        }
        this.snackbar.text = `Error: ${message}.`;
        this.snackbar.state = true;
        return;
      }

      this.submitting = true;
      const vm = this;

      try {
        const payload = {
          program: data.program.id,
          topic: data.topic.id,
          id: data.id,
          data: {
            type: data.type,
            title: data.title,
            settings: data.settings,
            theory: {
              ...data.theory,
              file: data.theory.file instanceof File ? data.theory.file : undefined,
            },
          },
          config: {
            onUploadProgress: (progress) => {
              vm.$refs.actForm[0].handleUpload(progress);
            },
          },
        };
        const program = this.$route.params.program;

        await this.$store.dispatch("activity/edit", payload);
        this.snackbar.text = `Aktivitas berhasil dibuat.`;
        this.snackbar.state = true;
        setTimeout(() => {
          this.$router.push({ name: "program-detail", params: { id: program } });
          this.submiting = false;
        }, 1000);
      } catch (error) {
        console.error(error);
        this.snackbar.text = `Error: ${error.message}.`;
        this.snackbar.state = true;
      }
      this.submitting = false;
    },
    // async submitQuiz (datas) {
    //   const vm = this

    //   try {
    //     const payload = {
    //       program: this.$route.params.program,
    //       topic: this.$route.params.topic,
    //       id: this.$route.params.id,
    //       data: datas,
    //       config: {
    //         onUploadProgress: (progress) => {
    //           vm.$refs.actForm[0].handleUpload(progress);
    //         },
    //       },
    //     };
  
    //     await this.$store.dispatch("activity/edit_quiz", payload);
  
    //     this.snackbar.text = `Aktivitas berhasil diedit.`;
    //     this.snackbar.state = true;
    //     setTimeout(() => {
    //       this.$router.push({ name: "program-detail", params: { id: this.$route.params.program } });
    //       this.submiting = false;
    //     }, 1000);
    //   } catch (error) {
    //     console.error(error);
    //     this.snackbar.text = `Error: ${error.message}.`;
    //     this.snackbar.state = true;
    //   }

    // },
    async fetchACtivity() {
      this.isLoading = true;
      try {
        const { data: activity } = await this.$store.dispatch("activity/show", {
          program: this.$route.params.program,
          topic: this.$route.params.topic,
          id: this.$route.params.id,
        });
        if (this.$route.name == "activity-edit") {
          this.title_topik = activity.topic.name;
          this.isDisabled = true;
        }
        activity.status = "publish";
        if(activity.settings.is_show_discussion){
          activity.settings.is_show_discussion = 1;
        }else{
          activity.settings.is_show_discussion=0;
        }

        if(activity.settings.set_minimum_value.is_set){
          activity.settings.set_minimum_value.is_set = 1;
        }else{
          activity.settings.set_minimum_value.is_set=0;
        }

        if(activity.settings.is_skip){
          activity.settings.is_skip = 1;
        }else{
          activity.settings.is_skip=0;
        }

        if(activity.settings.is_show_timer){
          activity.settings.is_show_timer = 1;
        }else{
          activity.settings.is_show_timer=0;
        }

        if(activity.settings.is_show_score){
          activity.settings.is_show_score = 1;
        }else{
          activity.settings.is_show_score=0;
        }
        this.activities.push(activity);
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    onDragStart(e, index) {
      e.dataTransfer.setData("index", index);
    },
    onDrag(e) {
      if (!e.clientY) return;
      if (e.clientY < 100) {
        window.scrollTo({
          top: window.pageYOffset - 100,
          behavior: "smooth",
        });
      }
      if (e.clientY > window.outerHeight - 100) {
        window.scrollTo({
          top: window.pageYOffset + 100,
          behavior: "smooth",
        });
      }
    },
    onDrop(e, position) {
      const index = e.dataTransfer.getData("index");
      const activity = this.activities.splice(index, 1);
      this.activities.splice(position, 0, ...activity);
    },
    save() {
      this.errors = null;
      this.submiting = true;

      setTimeout(() => {
        if (this.$refs.form.validate()) {
          let params = {
            name: this.title_topik,
            status: "publish",
            program_id: this.$route.params.id,
          };
          this.axios
            .post(`topic/v1/create`, params)
            .then((res) => res.data)
            .then((res) => {
              const data = res.data;
              this.submitting = false;
              this.snackbar.text = `Topik berhasil ditambahkan`;
              this.snackbar.state = true;
              this.aktifitas = true;
              this.simpan = false;
              this.isDisabled = true;
              this.$router.push({
                name: "activity-create",
                params: { program: this.$route.params.id, topic: data.id },
              });
            })
            .catch((res) => {
              this.submitting = false;
              if (res.response) {
                this.snackbar.text = res.response.data.message;
                this.snackbar.state = true;
              }
            });
        }
      }, 50);
    },
  },
};
</script>
