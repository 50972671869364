import axios from "axios";
import url from "./api";
// const base_url = "http://prakerja.com/v1";

const SET_LIST = "SET_LIST";
const SET_QUESTION = "SET_QUESTION";


// function objectToFormData(object) {
//   const formData = new FormData();
//   Object.keys(object).forEach((key) => {
//     if (object[key] == undefined) {
//       return;
//     }
//     if (!!object[key] && typeof object[key] == "object") {
//       if (object[key] instanceof File) {
//         formData.append(key, object[key], object[key].name);
//       } else {
//         for (let data of objectToFormData(object[key]).entries()) {
//           const obindex = data[0].indexOf("[");
//           const all = data[0].length;
//           formData.append(`${key}[${data[0].slice(0, obindex == -1 ? all : obindex)}]${data[0].slice(obindex == -1 ? all : obindex)}`, data[1]);
//         }
//       }
//     } else {
//       const value = typeof object[key] == "boolean" ? (object[key] ? 1 : 0) : object[key];
//       formData.append(key, value);
//     }
//   });
//   return formData;
// }

export default {
  namespaced: true,
  state: () => ({
    list: [],
    questions: []
  }),
  getters: {},
  mutations: {
    SET_LIST: (state, payload) => {
      // const index = state.list.findIndex((v) => v.topic == payload.topic);
      // if (index != -1) state.list.splice(index, 1);
      state.list.push(payload);
    },
    SET_QUESTION: (state, data) => (state.list = Array.isArray(data) ? data : []),
  },
  actions: {
    async fetch(context, payload) {
      const response = await axios
        .get(`${url.gameplay_list}`,  {params: payload.params})
        .then((res) => res.data);
      context.commit(SET_LIST, { data: response.data.list });
      return response;
    },
    async create(context, payload) {
      // const formData = objectToFormData(payload);
      const config = payload.config || {}
      const response = await axios
        .post(`${url.gameplay_create}`, payload, config)
        .then((res) => res.data);
      return response;
    },
    async show(context, payload) {
      return await axios.get(`${url.gameplay_show(payload)}`).then((res) => res.data);
    },
    async show_multi(context, payload) {
      return await axios.get(`${url.gameplay_show_multi}`, {
        params: payload
      }).then((res) => res.data);
    },
    async show_multi_choice(context, payload) {
      const response =  await axios.get(`${url.gameplay_show_multi}`, {
        params: payload
      }).then((res) => res.data);

      context.commit(SET_QUESTION, { data: response.data });
      return response;
    },
    async edit(context, payload) {
      // const formData = objectToFormData(payload.data);
      const formData = payload.data;
      const config = payload.config || {}
      const response = await axios
        .put(`${url.gameplay_update(payload.id)}`, formData, config)
        .then((res) => res.data);
      return response;
    },
    async delete(context, payload) {
      return await axios.delete(`${url.activity_delete(payload)}`).then((res) => res.data);
    },
  },
}