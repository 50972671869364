<template>
  <v-row>
    <v-col v-for="(question, index) in questions" :key="index" cols="12">
      <div class="rounded pa-4" style="border: 1px solid #9db0c6;">
        <div class="float-right">
          <!-- <h3>Nomor {{ index + 1 }}</h3> -->
          <v-btn icon @click.stop="deleteQuestion(index)">
            <v-icon> $delete </v-icon>
          </v-btn>
        </div>

        <v-row>
          <v-col cols="12">
            <div class="d-flex mb-4 justify-space-between align-center">
              <h3>Daftar Cari Kata</h3>
            </div>
            <div>
              Daftar Kata
            </div>
          </v-col>
          <v-col
            v-for="(option, indx) in question.options"
            :key="`${index}${indx}`"
            cols="12"
            style="position: relative"
            >
            <!-- @mouseover="$set(option, 'showDelete', true)"
            @mouseleave="$set(option, 'showDelete', false)" -->
            <div class="d-flex align-center">
              <div class="mr-4">{{ indx + 1 }}.</div>
              <v-text-field
                class="flex-grow-1 mr-12"
                hide-details="auto"
                outlined
                filled
                v-model="option.text"
                placeholder="Masukkan kata"
              ></v-text-field>
              <v-btn x-small absolute right top icon @click="question.options.splice(indx, 1)"
                ><v-icon>$delete</v-icon></v-btn
              >
            </div>
          </v-col>

          <v-col cols="12">
            <v-btn text @click="addOption(index)">
              <v-icon left>$add</v-icon>
              Kata
            </v-btn>
          </v-col>

          <v-col cols="12">
            <p class="mb-2">Poin</p>
            <v-text-field class="mb-2" v-model="question.point" placeholder="0" outlined filled color="primary" hint="Poin ini merupakan nilai perkata" persistent-hint hide-details="auto" />
          </v-col>
          <!-- <v-col cols="6">
            <p class="mb-2">Timer (detik)</p>
            <v-text-field class="mb-2" v-model="question.duration" outlined filled color="primary" hide-details="auto" />
          </v-col> -->
        </v-row>

        <v-alert v-show="question.errorData" type="error" text outlined>
          <div style="white-space: pre;">{{ question.errorData }}</div>
        </v-alert>

        <!-- <v-alert type="info" text outlined dense>
          <div style="font-size: 12px;">
            Jangan lupa klik <strong>Simpan Soal</strong> setelah selesai mengisi data dengan lengkap.
          </div>
        </v-alert> -->

        <div class="text-right">
          <v-btn color="primary" type="button" :loading="isLoading" class="white--text text-right" @click="savedGameplays(question, index)">
            Simpan Soal
          </v-btn>
        </div>
      </div>
    </v-col>

    <v-col v-if="questions.length == 0" cols="12">
      <v-btn color="primary" outlined @click="addQuestion">
        Tambah Soal
      </v-btn>
    </v-col>

    <v-dialog v-model="delete_question.show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span> <v-icon>$warning</v-icon> Hapus Soal </span>
        </v-card-title>
        <v-divider></v-divider>
        <div>
          <v-card-text>
            Apakah anda ingin menghapus soal ini?
          </v-card-text>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="delete_question.deleting" outlined color="primary" large @click="cancelDeleteQuestion()">
            Batal
          </v-btn>
          <v-btn
            :loading="delete_question.deleting"
            class="white--text"
            color="primary"
            large
            @click="confirmDeleteQuestion()"
          >
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const blankQuestion = {
  id: "",
  type: "",
  question: null,
  point: null,
  duration: 0,
  errorData:"",
  options: [{ text: "" }],
  correct_answers: [],
};
import model from "../../_mixins/model";

export default {
  mixins: [model],
  data: () => ({
    delete_question: { show: false, index: null, id: "" },
    snackbar: {
      state: false,
      text: "",
    },
    isSaveLastData: false,
    isLoading: null,
    timeout: 5000,
    questions: [],
    questionError: [],
    gameplays: []
  }),
  created() {
    this.fetchDetailGameplay();
  },
  methods: {
    async fetchDetailGameplay() {
      // const this.data.gameplays.toString()IDS = this.data.gameplays.toString()

      // console.log('IDS',IDS);

      
      const {data: detailQuiz} = await this.$store.dispatch("gameplay/show_multi", {
        ids: `${this.data.gameplays.toString()}`
      })
      
      this.questions = detailQuiz
      this.gameplays = this.data.gameplays
    },
    addQuestion() {
      this.questions.push({ ...JSON.parse(JSON.stringify(blankQuestion)), type: "finding_word" });
      this.snackbar.text = ""
    },
    addOption(index) {
      this.questions[index].options.push({ text: "" });
    },
    deleteOption(index, indx) {
      this.questions[index].options.splice(indx, 1);
    },
    deleteQuestion(data, index) {
      this.delete_question.show = true;
      this.delete_question.index = index;
      this.delete_question.id = data.id;
      this.snackbar.text = ""
    },
    cancelDeleteQuestion() {
      this.delete_question.show = false;
      this.delete_question.index = null;
      this.delete_question.id = "";
      this.snackbar.text = ""
    },
    confirmDeleteQuestion() {
      this.questions.splice(this.delete_question.index, 1);

      // DELETE DATA GAMEPLAY ID
      let indexData = this.gameplays.findIndex((item) => this.delete_question.id  == item);
      this.gameplays.splice(indexData, 1)

      this.delete_question.show = false;
      this.delete_question.index = null;
      this.delete_question.id = "";
      this.snackbar.text = ""

      this.$emit('click:submit',{
        type: "finding_word",
        gameplays: this.gameplays,
        saving: false
      })
    },
    validate(key) {
      var isValid = true;
      // for (let i = 0; i < this.questions.length; i++) {
      // }
      
      const question = this.questions[key]; // or question
      question.errorData = ""

      if (!(question.options && question.options.length)) {
        isValid = false
        question.errorData += "Daftar kata tidak boleh kosong\n"
      }
      
      if(question.options.length > 0){
        question.options.forEach((item, index) => {
          if(!item.text) {
            isValid = false
            question.errorData += `Kata nomor ${index + 1} masih kosong\n`
          }
        })
      }

      if (!question.point) {
        isValid = false
        question.errorData += "Poin tidak boleh kosong atau 0\n"
      }
    

      // console.log(question);

      setTimeout(() => {
        question.errorData = ""
      }, 3000);

      return isValid;
    },

    async saveGameplay(data) {
      // const headers = { "content-type": "application/json" };
      if (data.id) {
        let payload = {
          id: data.id,
          data: data
        }
        return await this.$store.dispatch(`gameplay/edit`, payload);
      } else {
        return await this.$store.dispatch(`gameplay/create`, data);
      }
    },

    async savedGameplays(question, index) {
      
      this.isLoading = true

      let isValid = this.validate(index)
      
      let answer = []
      
      question.options.forEach((item) => {
        answer.push(item.text)
      });
      
      let dataQuestion = {
        id: question.id,
        type: "finding_word",
        question: null,
        point: Number(question.point),
        duration:  Number(question.duration),
        options: question.options,
        matching_options: question.options,
        correct_answers: answer,
      }
      
      if (isValid) {
        try {
          await this.saveGameplay(dataQuestion)
            .then((res) => {
              let response = res.data
  
              this.questions[index].id = response.id
  
              let gametrue = this.gameplays.includes(response.id)
  
              if(!gametrue){
                this.gameplays.push(response.id);
              }
  
              this.$emit('click:submit',{
                type: "finding_word",
                gameplays: this.gameplays,
                saving: true
              })
  
              this.isLoading = false;
            }).catch(async (error) => {
              this.isLoading = false;
              this.snackbar.text = error.message
              throw error;
              // reject(error);
            });
        } catch (error) {
          this.isLoading = false;
          throw error;
        }
      }else {
        this.isLoading = false
      }


    },
  },
};
</script>
